import logo from './logo.svg';
import './App.css';
import Container from './Component/Container';

function App() {
  return (
    <div>
      <Container></Container>
           
    </div>
  );
}

export default App;
