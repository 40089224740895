import React, { Component } from 'react';
import Homepage from './Homepage';
import Header from './Header';
import About from './About';
import Tours from './Tours';
import Footer from './Footer';
import { BrowserRouter as Router, Route, Routes,withRouter } from "react-router-dom";
import Enquiry from './Enquiry';
import Login from './Login';
import axios from "axios";
import swal from 'sweetalert';

export default class Container extends Component {
  constructor(props) {
    super(props)
    this.state = {
       isLogin : false,
       amobile : "",
       apassword : "",
    }

    
  }

  componentDidMount()
  {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('status')
    if(token=== "LoggedOut")
    {
      sessionStorage.removeItem("Mobile_Number");
    }

    const mob = JSON.parse(sessionStorage.getItem('Mobile_Number'));
    if(mob)
    {
      this.setState({isLogin : true, amobile : mob});   
    }

  }

  
  loginHandler = async (event)=> {
    event.preventDefault();
   
    this.state.amobile = event.target.amobile.value;
    this.state.apassword = event.target.apass.value;

    const sendData = {
      Mobile_Number : this.state.amobile,
      Password :  this.state.apassword
    }

    await axios.post("http://admin.startravels52.com/Api/Login.php",sendData)
    .then((response)=>{
      if(response.data.data.status === "invalid")
      {

        swal({
          title: "Oops !! ",
          text: "Please check the credentials!!",
          icon: "error",
          button: "Back",
        });

      }

      else if(response.data.data.status === "valid")
      {
        sessionStorage.setItem('Mobile_Number',JSON.stringify( this.state.amobile));
        // localStorage.setItem('Mobile_Number',JSON.stringify( this.state.amobile));
        swal({
          title: "Success!",
          text: "Login Successfull at Star Travels!",
          icon: "success",
          buttons: {
            catch: {
              text: "Proceed !",
              value: "catch",
            },
          },
          
          })
          
          .then((value) => {
            switch (value) {
              case "catch":
                this.setState({isLogin:true});
                window.open("http://admin.startravels52.com?Key="+this.state.amobile,"_self");
                
              break;

              default:
                
              }
          });

      }

      else
      {
        swal({
          title: "Oops !! ",
          text: "Please check the credentials!!",
          icon: "error",
          button: "Back",
        });
      }
    })
    .catch((error)=>{
      alert(error.message);
    });
  }

  queryHandler = async (event) => {
    event.preventDefault();
    const Name= event.target.name.value;
    const Mobile = event.target.phone.value;
    const Email = event.target.email.value;
    const Message = event.target.message.value;
    
    const sendData = {
      Ename : Name,
      Emobile : Mobile,
      Eemail : Email,
      Emessage : Message
    }
   await axios.post("http://admin.startravels52.com/Api/Enquiry.php",sendData)
  .then((response)=>{
    if(response.data.data.status === "invalid")
    {
      swal({
        title: "Oops !! ",
        text: "Query not sent ! Try after some time.",
        icon: "error",
        button: "Back",
      });
    }

    else if(response.data.data.status === "valid")
    {
      swal({
        title: "Success!",
        text: "Query sent to Star Travels ! You will be contact soon.",
        icon: "success",
        button: "Back",
      });

  }

    else
    {
      swal({
        title: "Oops !! ",
        text: "Query not sent ! Try after some time.",
        icon: "error",
        button: "Back",
      });
    }
  })
  .catch((error)=>{
    alert(error.message);
  });
    
  }

  render() {
    return (
      <div>

      <Router>
          <Homepage isLogin ={this.state.isLogin} mobile={this.state.amobile}></Homepage>
          <Routes>
                <Route path="/" element={
                <div> 
                <Header></Header>
                <About></About>
                <Tours></Tours>
                <Footer></Footer>
                </div>
              } />


                <Route path="/EnquireUs" element={
                <div> 
                <Enquiry submitenq = {this.queryHandler}></Enquiry>
                <Footer></Footer>
                </div>
              } />
                
                <Route path="/AgentLogin" element={
                <div> 
                <Login checkLogin={this.loginHandler}></Login>
                <Footer></Footer>
                </div>
              } />


          </Routes>
      </Router>
        
      </div>
    )
  }
}
