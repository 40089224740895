import React, { Component } from 'react'

export default class About extends Component {
    constructor(props) {
      super(props)
    
      this.state = {}
    }
    
  render() {
    return (
      <div>
        <section class="about cover section-padding" id="aboutus">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-30 animate-box" data-animate-effect="fadeInUp">
                    <div class="section-subtitle">The best travel agency</div>
                    <div class="section-title">Discover the <span>world</span> with our guide</div>
                    <p>No matter who you are looking to travel with, you can depend on our 2,500 trusted operators to make sure everything’s taken care of.</p>
                    <ul class="list-unstyled about-list mb-30">
                        <li>
                            <div class="about-list-icon"> <span class="ti-check"></span> </div>
                            <div class="about-list-text">
                                <p>10 Years of Experience</p>
                            </div>
                        </li>
                        <li>
                            <div class="about-list-icon"> <span class="ti-check"></span> </div>
                            <div class="about-list-text">
                                <p>150+ Tour Destinations</p>
                            </div>
                        </li>
                    </ul>
                    
                    <div class="phone-call mb-30">
                        <div class="icon"><span class="flaticon-phone-call"></span></div>
                        <div class="text">
                            <p>For information</p> <a href="tel:9806761974">98067 61974</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 offset-md-1 animate-box" data-animate-effect="fadeInUp">
                    <div class="img-exp">
                        <div class="about-img">
                            <div class="img"> <img src={'img/about.jpg'} class="img-fluid" alt="" /> </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
      </div>
    )
  }

}
