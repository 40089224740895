import React from 'react'

export default function AgentLogin(props) {
  return (
    <div>
        
          <div className="banner-header section-padding back-position-center valign bg-img bg-fixed" data-overlay-dark="5" style={{backgroundImage : "url('img/slider/15.jpg')"}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12 caption mt-90">
                    <h5>Manage the Travel</h5>
                    <h1>Agent <span>Login</span></h1>
                </div>
            </div>
        </div>
    </div>

         <section className="contact section-padding">
        <div className="container">
            <div className="row mb-90">
                <div className="col-md-12 text-center">
                    <div className="sidebar">
                        <div className="right-sidebar">
                            <div className="right-sidebar item">
                                <h2>Agent Login</h2>
                                <form className="right-sidebar item-form contact__form" onSubmit={props.checkLogin}>
                                    
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <input name="amobile" type="text" placeholder="Your Mobile Number*" required={true} minLength='10'/>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <input name="apass" type="password" placeholder="Your Password*" required={true} minLength='5' />
                                        </div>

                                        
                                        <div className="col-md-12">
                                            <button className="btn btn-primary"><span>Login</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
            
        </div>
    </section>
    </div>
  )
}
