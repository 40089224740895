import React, { Component } from 'react'

export default class Header extends Component {
  render() {
    return (
      <div>
    <header class="header slider-fade">
        <div class="owl-carousel owl-theme owl-loaded owl-drag">
           
            <div class="text-center item bg-img" data-overlay-dark="5" style={{backgroundImage : "url('img/slider/1.jpg')"}}>
                <div class="v-middle caption">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <h4>Let's travel the world with us</h4>
                                <h1>Explore The World With <span>Travol</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center item bg-img" data-overlay-dark="5" style={{backgroundImage : "url('img/slider/2.jpg')"}}>
                <div class="v-middle caption">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <h4>Let's travel the world with us</h4>
                                <h1>Explore Rome With <span>Travel</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </header>
      </div>
    )
  }
}
