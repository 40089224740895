import React, { Component } from 'react'
import {Link} from "react-router-dom";
import { HashLink as Link1 } from 'react-router-hash-link';

export default class Homepage extends Component {
  constructor(props) {
    super(props)
  
    this.state = {}
  }
  render() {
    return (
      <div>
   
    <div class="progress-wrap cursor-pointer">
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
    </div>
   
    <nav class="navbar navbar-expand-lg">
        <div class="container">
           
            <div class="logo-wrapper navbar-brand valign">
                <Link to="/">
                    <div class="logo"> <img src="img/logo-light.png" class="logo-img" alt="" /> </div>
                </Link>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span class="icon-bar"><i class="ti-line-double"></i></span> </button>
           
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item"> <span class="nav-link active"><Link to="/"> Home</Link></span>
                    </li>
                    <li class="nav-item"><Link1 class="nav-link" smooth to="/#aboutus">About</Link1></li>
                    <li class="nav-item"><Link1 class="nav-link" smooth to="/#Destinations">Destinations</Link1></li>
                    <li class="nav-item"><Link1 class="nav-link" smooth to="/#Contact">Contact</Link1></li>
                    <li class="nav-item"><Link class="nav-link" to="/EnquireUs">Send Enquiry</Link></li>
                    {this.props.isLogin 
                    ? <li class="nav-item"><a class="nav-link" href ="http://www.admin.startravels52.com/">My Account</a></li>
                    : <li class="nav-item"><Link class="nav-link" to="/AgentLogin">Agent Login</Link></li>}
                    
                </ul>
            </div>
        </div>
    </nav>

      </div>
    )
  }
}
