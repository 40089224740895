import React, { Component } from 'react'

export default class Enquiry extends Component {
    constructor(props) {
      super(props)
    
      this.state = {}
    }
  render() {
    return (
      <div>

<div className="banner-header section-padding back-position-center valign bg-img bg-fixed" data-overlay-dark="5" style={{backgroundImage : "url('img/slider/15.jpg')"}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12 caption mt-90">
                    <h5>Get in touch</h5>
                    <h1>Contact <span>Us</span></h1>
                </div>
            </div>
        </div>
    </div>

         <section className="contact section-padding">
        <div className="container">
            <div className="row mb-90">
                <div className="col-md-6 mb-60">
                    <h3>Star Travels Pvt Ltd.</h3>
                    <p>Discover the <span>world</span> with our guide</p>
                    <div className="phone-call mb-30">
                        <div className="icon"><span className="flaticon-phone-call"></span></div>
                        <div className="text">
                            <p>Phone</p> <a href="tel:9806761974">98067 61974</a>
                        </div>
                    </div>
                    <div className="phone-call mb-30">
                        <div className="icon"><span className="flaticon-message"></span></div>
                        <div className="text">
                            <p>e-Mail Address</p> <a href="mailto:startravels52@gmail.com">startravels52@gmail.com</a>
                        </div>
                    </div>
                    <div className="phone-call">
                        <div className="icon"><span className="flaticon-placeholder"></span></div>
                        <div className="text">
                            <p>Location</p> Shop No.2, Piplani BHEL
                            <br />Bhopal - Madhya Pradesh - India
                        </div>
                    </div>
                </div>
                <div className="col-md-5 mb-30 offset-md-1">
                    <div className="sidebar">
                        <div className="right-sidebar">
                            <div className="right-sidebar item">
                                <h2>Get in touch</h2>
                                <form className="right-sidebar item-form contact__form" onSubmit={this.props.submitenq}>
                                    
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <input name="name" type="text" placeholder="Your Name *" required="true" />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <input name="email" type="email" placeholder="Your Email *" required="true" />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <input name="phone" type="text" placeholder="Your Number *" required="true" />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <textarea name="message" id="message" cols="30" rows="4" placeholder="Message *" required="true"></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="butn-dark"><a href="#0"><span>Send Message</span></a></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
            
        </div>
    </section>
      </div>
    )
  }
}
