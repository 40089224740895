import React, { Component } from 'react'

export default class Tours extends Component {
  render() {
    return (
      <div>
        <section class="tours1 section-padding bg-lightnav" data-scroll-index="1" id="Destinations">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-subtitle"><span>Choose your place</span></div>
                    <div class="section-title">Popular <span>Tours</span></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="item">
                        <div class="position-re o-hidden"> <img src="img/tours/maldives1.jpg" alt="" /> </div> <span class="category"><a href="#0">$2.500</a></span>
                        <div class="con">
                            <div class="rating"> <i class="star active"></i> <i class="star active"></i> <i class="star active"></i> <i class="star active"></i> <i class="star"></i>
                                <div class="reviews-count">(2 Reviews)</div>
                            </div>
                            <h5><a href="tour-details.html">Maldives Tour</a></h5>
                            <div class="line"></div>
                            <div class="row facilities">
                                <div class="col col-md-12">
                                    <ul>
                                        <li><i class="ti-time"></i> 10 Days</li>
                                        <li><i class="ti-user"></i> 12+</li>
                                        <li><i class="ti-location-pin"></i> Maldives</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item">
                        <div class="position-re o-hidden"><img src="img/tours/italy1.jpg" alt="" /></div> <span class="category"><a href="#0">$1.300</a></span>
                        <div class="con">
                            <h5><a href="tour-details.html">Italy Tour</a> </h5>
                            <div class="line"></div>
                            <div class="row facilities">
                                <div class="col col-md-12">
                                    <ul>
                                        <li><i class="ti-time"></i> 6 Days</li>
                                        <li><i class="ti-user"></i> 10+</li>
                                        <li><i class="ti-location-pin"></i> Italy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item">
                        <div class="position-re o-hidden"><img src="img/tours/france1.jpg" alt="" /></div> <span class="category"><a href="#0">$400</a></span>
                        <div class="con">
                            <h5><a href="tour-details.html">France Tour</a> </h5>
                            <div class="line"></div>
                            <div class="row facilities">
                                <div class="col col-md-12">
                                    <ul>
                                        <li><i class="ti-time"></i> 10 Days</li>
                                        <li><i class="ti-user"></i> 6+</li>
                                        <li><i class="ti-location-pin"></i> France</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item">
                        <div class="position-re o-hidden"><img src="img/tours/greece1.jpg" alt="" /></div> <span class="category"><a href="#">$500</a></span>
                        <div class="con">
                            <h5><a href="tour-details.html">Greece Tour</a> </h5>
                            <div class="line"></div>
                            <div class="row facilities">
                                <div class="col col-md-12">
                                    <ul>
                                        <li><i class="ti-time"></i> 10 Days</li>
                                        <li><i class="ti-user"></i> 12+</li>
                                        <li><i class="ti-location-pin"></i> Greece</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item">
                        <div class="position-re o-hidden"><img src="img/tours/canada1.jpg" alt="" /></div> <span class="category"><a href="#0">$300</a></span>
                        <div class="con">
                            <h5><a href="tour-details.html">Canada Tour</a></h5>
                            <div class="line"></div>
                            <div class="row facilities">
                                <div class="col col-md-12">
                                    <ul>
                                        <li><i class="ti-time"></i> 7 Days</li>
                                        <li><i class="ti-user"></i> 10+</li>
                                        <li><i class="ti-location-pin"></i> Canada</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
      </div>
    )
  }
}
